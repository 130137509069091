import React from 'react';

import Box from 'components/Box/Box';
import FormField from 'components/FormField/FormField';
import Button from 'components/Button/Button';
import Result from 'components/Result/Result';
import Highlight from 'components/Highlight/Highlight';
import Description from 'components/Description/Description';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import AppWrapper from 'components/AppWrapper/AppWrapper';

const ConvertidorTallaTenis = () => {
    const [longitudPie, setLongitudPie] = React.useState('');
    const [tallaMx, setTallaMx] = React.useState('');
    const [tallaUsHombre, setTallaUsHombre] = React.useState('');
    const [tallaUsMujer, setTallaUsMujer] = React.useState('');
    const [error, setError] = React.useState('');

    const reiniciarApp = () => {
        setLongitudPie('');
        setTallaMx('');
        setTallaUsHombre('');
        setTallaUsMujer('');
        setError('');
    }

    const convertirTallaTenis = () => {
        if (!longitudPie) {
            setError('¡Por favor ingrese la longitud del pie!');
            return false;
        }

        const tablaDeTallas = [
            { longitud: 22.1, mx: 2, usHombre: 4, usMujer: 5 },
            { longitud: 22.5, mx: 2.5, usHombre: 4.5, usMujer: 5.5 },
            { longitud: 22.9, mx: 3, usHombre: 5, usMujer: 6 },
            { longitud: 23.3, mx: 3.5, usHombre: 5.5, usMujer: 6.5 },
            { longitud: 23.8, mx: 4, usHombre: 6, usMujer: 7 },
            { longitud: 24.2, mx: 4.5, usHombre: 6.5, usMujer: 7.5 },
            { longitud: 24.6, mx: 5, usHombre: 7, usMujer: 8 },
            { longitud: 25.0, mx: 5.5, usHombre: 7.5, usMujer: 8.5 },
            { longitud: 25.5, mx: 6, usHombre: 8, usMujer: 9 },
            { longitud: 25.9, mx: 6.5, usHombre: 8.5, usMujer: 9.5 },
            { longitud: 26.3, mx: 7, usHombre: 9, usMujer: 10 },
            { longitud: 26.7, mx: 7.5, usHombre: 9.5, usMujer: 10.5 },
            { longitud: 27.1, mx: 8, usHombre: 10, usMujer: 11 },
            { longitud: 27.6, mx: 8.5, usHombre: 10.5, usMujer: 11.5 },
            { longitud: 28.0, mx: 9, usHombre: 11, usMujer: 12 },
            { longitud: 28.4, mx: 9.5, usHombre: 11.5, usMujer: 12.5 },
            { longitud: 28.8, mx: 10, usHombre: 12, usMujer: 13 },
            { longitud: 29.3, mx: 10.5, usHombre: 12.5, usMujer: 13.5 },
            { longitud: 29.7, mx: 11, usHombre: 13, usMujer: 14 },
            { longitud: 30.1, mx: 11.5, usHombre: 13.5, usMujer: 14.5 },
            { longitud: 30.5, mx: 12, usHombre: 14, usMujer: 15 },
            { longitud: 31.0, mx: 12.5, usHombre: 14.5, usMujer: 15.5 },
            { longitud: 31.4, mx: 13, usHombre: 15, usMujer: null },
        ];

        const tallaCoincidente = tablaDeTallas.find(talla => talla.longitud === parseFloat(longitudPie));

        if (tallaCoincidente) {
            setTallaMx(tallaCoincidente.mx);
            setTallaUsHombre(tallaCoincidente.usHombre);
            setTallaUsMujer(tallaCoincidente.usMujer ? tallaCoincidente.usMujer : 'N/A');
            setError('');
        } else {
            setError('No se encontró una talla coincidente para la longitud del pie ingresada.');
        }
    }

    return (
        <AppWrapper>
            <Box>
                <h2>Convertidor de Talla de Tenis</h2>
                <Description>
                    Convierte la longitud de tu pie (en cm) a tallas de tenis mexicanas, estadounidenses para hombres, mujeres y niños, y también a tallas europeas.
                </Description>

                {tallaMx || tallaUsHombre || tallaUsMujer ? (
                    <>
                        <Result>
                            <p>Tu talla de tenis en México es <Highlight>{tallaMx}</Highlight></p>
                            <p>Tu talla de tenis en EE.UU. para hombres es <Highlight>{tallaUsHombre}</Highlight></p>
                            <p>Tu talla de tenis en EE.UU. para mujeres es <Highlight>{tallaUsMujer}</Highlight></p>
                        </Result>
                        <Button onClick={reiniciarApp}>Reiniciar</Button>
                    </>
                ) : (
                    <>
                        <FormWrapper>
                            <FormField
                                id="longitudPie"
                                label="Longitud del Pie (en cm)"
                                type="number"
                                value={longitudPie}
                                onChange={(event) => setLongitudPie(event.target.value)}
                                placeholder='Ingresa la longitud de tu pie (ej. 25.0)'
                                submitMethod={convertirTallaTenis}
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </FormWrapper>
                        <Button onClick={convertirTallaTenis}>Convertir</Button>
                    </>
                )}
            </Box>
            <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
                <h3 style={{ marginBottom: '10px' }}>Cómo Elegir los Tenis Perfectos</h3>
                <p style={{ marginBottom: '20px' }}>
                    Encontrar los tenis adecuados es fundamental para tu comodidad y estilo. Sigue estos consejos para elegir los tenis perfectos:
                    <ol>
                        <li>Considera la ocasión y el entorno donde usarás los tenis para elegir el estilo adecuado.</li>
                        <li>Elige materiales de calidad que sean duraderos y transpirables para mantener tus pies cómodos durante todo el día.</li>
                        <li>Prueba los tenis al final del día, cuando tus pies estén más hinchados, para garantizar un ajuste perfecto.</li>
                        <li>Consulta las guías de tallas y asegúrate de que los tenis se ajusten correctamente para evitar molestias y ampollas.</li>
                    </ol>
                    Con estos consejos, encontrarás los tenis ideales que no solo complementen tu estilo, sino que también te brinden confort duradero.
                </p>
                <h3 style={{ marginBottom: '10px' }}>Beneficios de Usar Tenis de Calidad</h3>
                <p style={{ marginBottom: '20px' }}>
                    Usar tenis de calidad no solo mejora tu apariencia, sino que también cuida de tu salud. Aquí algunos beneficios clave:
                    <ul>
                        <li>Reducción del riesgo de problemas ortopédicos como juanetes o fascitis plantar gracias al soporte adecuado.</li>
                        <li>Mayor durabilidad y resistencia, lo que permite un uso prolongado sin deterioro prematuro.</li>
                        <li>Mejora de la postura y alineación corporal al usar tenis que proporcionen el soporte adecuado para tus pies.</li>
                    </ul>
                    Invierte en la calidad de tus tenis para cuidar tu bienestar y estilo personal. Encuentra los tenis perfectos y disfruta de los beneficios que ofrecen a tu salud y confort diario.
                </p>
                <h3 style={{ marginBottom: '10px' }}>Cómo Medir tu Tamaño de Tenis</h3>
                <p style={{ marginBottom: '20px' }}>
                    Medir tu tamaño de tenis correctamente es crucial para asegurar un buen ajuste. Sigue estos pasos para medir tu pie:
                    <ol>
                        <li>Coloca una hoja de papel en el suelo, pegada a la pared.</li>
                        <li>Coloca tu pie descalzo sobre la hoja, asegurándote de que el talón esté contra la pared.</li>
                        <li>Marca el punto más largo de tu pie en la hoja.</li>
                        <li>Usa una regla para medir la distancia desde la pared hasta la marca.</li>
                        <li>Consulta la guía de tallas de tenis para encontrar tu tamaño basado en la longitud medida.</li>
                    </ol>
                </p>
                <h3 style={{ marginBottom: '10px' }}>Tabla de Tallas de Tenis</h3>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Longitud del Pie (cm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Talla MX</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Talla US Hombre</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Talla US Mujer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>22.1</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>2</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>4</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>22.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>2.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>4.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>22.9</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>3</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>23.3</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>3.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>23.8</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>4</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>24.2</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>4.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>24.6</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>25.0</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>5.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>25.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>25.9</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>6.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>26.3</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>26.7</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>7.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>27.1</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>27.6</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>8.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>28.0</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>28.4</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>9.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>28.8</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>29.3</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>10.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>13.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>29.7</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>13</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>30.1</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>11.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>13.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>14.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>30.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>14</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>31.0</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>12.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>14.5</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>15.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>31.4</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>13</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>15</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>N/A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </AppWrapper>
    );
};

export default ConvertidorTallaTenis;

