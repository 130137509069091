import React from 'react';

import Box from 'components/Box/Box';
import FormField from 'components/FormField/FormField';
import Button from 'components/Button/Button';
import Result from 'components/Result/Result';
import Highlight from 'components/Highlight/Highlight';
import Description from 'components/Description/Description';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import AppWrapper from 'components/AppWrapper/AppWrapper';

const ConvertidorTallaPantalones = () => {
    const [cintura, setCintura] = React.useState('');
    const [cadera, setCadera] = React.useState('');
    const [largo, setLargo] = React.useState('');
    const [etiquetaTalla, setEtiquetaTalla] = React.useState('');
    const [error, setError] = React.useState('');

    const reiniciarApp = () => {
        setCintura('');
        setCadera('');
        setLargo('');
        setEtiquetaTalla('');
        setError('');
    }

    const convertirTallaPantalones = () => {
        if (!cintura || !cadera || !largo) {
            setError('¡Por favor ingrese las medidas de cintura, cadera y largo!');
            return false;
        }

        const tablaDeTallas = [
            { etiqueta: 'XS', rangoCintura: [71, 74], rangoCadera: [82, 85], largo: 81 },
            { etiqueta: 'S', rangoCintura: [75, 80], rangoCadera: [86, 91], largo: 81.5 },
            { etiqueta: 'M', rangoCintura: [81, 88], rangoCadera: [92, 99], largo: 82 },
            { etiqueta: 'L', rangoCintura: [89, 96], rangoCadera: [100, 107], largo: 82.5 },
            { etiqueta: 'XL', rangoCintura: [97, 106], rangoCadera: [108, 116], largo: 83 },
            { etiqueta: '2XL', rangoCintura: [107, 119], rangoCadera: [117, 125], largo: 82.5 },
            { etiqueta: '3XL', rangoCintura: [120, 132], rangoCadera: [126, 135], largo: 82 },
        ];

        const tallaCoincidente = tablaDeTallas.find(talla => 
            cintura >= talla.rangoCintura[0] && cintura <= talla.rangoCintura[1] &&
            cadera >= talla.rangoCadera[0] && cadera <= talla.rangoCadera[1] &&
            largo === talla.largo
        );

        if (tallaCoincidente) {
            setEtiquetaTalla(tallaCoincidente.etiqueta);
            setError('');
        } else {
            setError('No se encontró una talla coincidente para las medidas ingresadas.');
        }
    }

    return (
        <AppWrapper>
            <Box>
                <h2>Convertidor de Tallas de Pantalones y Shorts para Hombres</h2>
                <Description>Convierte tus medidas de cintura, cadera y largo para encontrar tu talla de pantalones y shorts.</Description>

                {etiquetaTalla ? (
                    <>
                        <Result>
                            <p>Tu talla es <Highlight>{etiquetaTalla}</Highlight></p>
                        </Result>
                        <Button onClick={reiniciarApp}>Reiniciar</Button>
                    </>
                ) : (
                    <>
                        <FormWrapper>
                            <FormField
                                id="cintura"
                                label="Cintura (en cm)"
                                type="number"
                                value={cintura}
                                onChange={(event) => setCintura(event.target.value)}
                                placeholder='Ingresa la medida de la cintura (ej. 85)'
                                submitMethod={convertirTallaPantalones}
                            />
                            <FormField
                                id="cadera"
                                label="Cadera (en cm)"
                                type="number"
                                value={cadera}
                                onChange={(event) => setCadera(event.target.value)}
                                placeholder='Ingresa la medida de la cadera (ej. 100)'
                                submitMethod={convertirTallaPantalones}
                            />
                            <FormField
                                id="largo"
                                label="Largo (en cm)"
                                type="number"
                                value={largo}
                                onChange={(event) => setLargo(event.target.value)}
                                placeholder='Ingresa la medida del largo (ej. 82)'
                                submitMethod={convertirTallaPantalones}
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </FormWrapper>
                        <Button onClick={convertirTallaPantalones}>Convertir</Button>
                    </>
                )}
            </Box>

            <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
                <h3 style={{ marginBottom: '10px' }}>Cómo Medir Tu Talla de Pantalones y Shorts</h3>
                <p style={{ marginBottom: '20px' }}>
                    Para medir tu talla de pantalones y shorts con precisión, sigue estos sencillos pasos:
                    <ol>
                        <li>Usa una cinta métrica para medir la circunferencia de tu cintura. Asegúrate de que la cinta esté bien ajustada pero no demasiado apretada.</li>
                        <li>Anota la medida en centímetros. Esta es tu medida de "cintura".</li>
                        <li>Luego, mide alrededor de la parte más ancha de tus caderas. La cinta métrica debe estar nivelada y cómoda.</li>
                        <li>Anota esta medida en centímetros. Esta es tu medida de "cadera".</li>
                        <li>Finalmente, mide la longitud desde tu cintura hasta donde deseas que llegue el pantalón o short. Anota esta medida en centímetros. Esta es tu medida de "largo".</li>
                    </ol>
                    Obtener la talla correcta de pantalones y shorts es esencial para tu comodidad y apariencia. Una medida precisa asegurará que tus pantalones y shorts se ajusten perfectamente.
                </p>
                <h3 style={{ marginBottom: '10px' }}>¿Por Qué Usar un Convertidor de Tallas de Pantalones?</h3>
                <p style={{ marginBottom: '20px' }}>
                    Las tallas de pantalones pueden variar significativamente entre diferentes países y marcas. Nuestro convertidor de talla de pantalones te ayuda a convertir fácilmente tus medidas para encontrar la talla perfecta. Ya sea que estés comprando pantalones en línea o en una tienda, conocer tu talla correcta asegurará un ajuste perfecto y cómodo.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Usar nuestro convertidor de talla de pantalones es simple y directo. Solo mide tu cintura, cadera y largo, ingresa las medidas en el convertidor y obtén instantáneamente la talla correspondiente.
                </p>
                <h3 style={{ marginBottom: '10px' }}>Entendiendo las Tallas de Pantalones</h3>
                <p style={{ marginBottom: '20px' }}>
                    Las tallas de pantalones se determinan en función de la medida de la cintura, la medida de la cadera y la longitud del pantalón. Diferentes marcas y regiones pueden tener variaciones en sus tallas, por lo que es importante usar un convertidor de talla para obtener la medida más precisa.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Medir la talla de pantalones con precisión es crucial tanto para la comodidad como para asegurar que los pantalones proporcionen el ajuste adecuado. Usa nuestro convertidor para encontrar la talla perfecta y asegurarte de que tus pantalones se ajusten de manera óptima.
                </p>
                
                {/* Table with size chart */}
                <h3 style={{ marginBottom: '10px' }}>Tabla de Tallas de Pantalones</h3>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Etiqueta</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Rango de Cintura (cm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Rango de Cadera (cm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Largo (cm)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>XS</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>71 - 74</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>82 - 85</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>81</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>S</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>75 - 80</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>86 - 91</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>81.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>M</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>81 - 88</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>92 - 99</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>82</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>L</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>89 - 96</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>100 - 107</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>82.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>XL</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>97 - 106</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>108 - 116</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>83</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>2XL</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>107 - 119</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>117 - 125</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>82.5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>3XL</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>120 - 132</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>126 - 135</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>82</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </AppWrapper>
    );
}

export default ConvertidorTallaPantalones;


