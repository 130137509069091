import React from 'react';

import Box from 'components/Box/Box';
import FormField from 'components/FormField/FormField';
import Button from 'components/Button/Button';
import Result from 'components/Result/Result';
import Highlight from 'components/Highlight/Highlight';
import Description from 'components/Description/Description';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import AppWrapper from 'components/AppWrapper/AppWrapper';

const RingSizeConverter = () => {
    const [circunferencia, setCircunferencia] = React.useState('');
    const [tallaEuropea, setTallaEuropea] = React.useState('');
    const [tallaUs, setTallaUs] = React.useState('');
    const [error, setError] = React.useState('');

    const reiniciarApp = () => {
        setCircunferencia('');
        setTallaEuropea('');
        setTallaUs('');
        setError('');
    }

    const convertirTallaDeAnillo = () => {
        if (!circunferencia) {
            setError('¡Por favor ingrese la circunferencia!');
            return false;
        }

        const tablaDeTallas = [
            { europea: 6, circunferencia: 46, diámetro: 14.6, us: 4 },
            { europea: 7, circunferencia: 47, diámetro: 15.0, us: 4 },
            { europea: 8, circunferencia: 48, diámetro: 15.3, us: 4.5 },
            { europea: 9, circunferencia: 49, diámetro: 15.6, us: 5 },
            { europea: 10, circunferencia: 50, diámetro: 15.9, us: 5.5 },
            { europea: 11, circunferencia: 51, diámetro: 16.2, us: 6 },
            { europea: 12, circunferencia: 52, diámetro: 16.5, us: 6 },
            { europea: 13, circunferencia: 53, diámetro: 16.8, us: 6.5 },
            { europea: 14, circunferencia: 54, diámetro: 17.2, us: 7 },
            { europea: 15, circunferencia: 55, diámetro: 17.5, us: 7.5 },
            { europea: 16, circunferencia: 56, diámetro: 17.8, us: 8 },
            { europea: 17, circunferencia: 57, diámetro: 18.1, us: 8 },
            { europea: 18, circunferencia: 58, diámetro: 18.4, us: 8.5 },
            { europea: 19, circunferencia: 59, diámetro: 18.8, us: 9 },
            { europea: 20, circunferencia: 60, diámetro: 19.1, us: 9 },
            { europea: 21, circunferencia: 61, diámetro: 19.4, us: 9.5 },
            { europea: 22, circunferencia: 62, diámetro: 19.7, us: 10 },
            { europea: 23, circunferencia: 63, diámetro: 20.0, us: 10.5 },
            { europea: 24, circunferencia: 64, diámetro: 20.3, us: 10.5 },
            { europea: 25, circunferencia: 65, diámetro: 20.6, us: 11 },
            { europea: 26, circunferencia: 66, diámetro: 21.0, us: 11 },
            { europea: 27, circunferencia: 67, diámetro: 21.3, us: 11.5 },
            { europea: 28, circunferencia: 68, diámetro: 21.6, us: 12 },
            { europea: 29, circunferencia: 69, diámetro: 22.0, us: 13 },
            { europea: 30, circunferencia: 70, diámetro: 22.3, us: 13 },
            { europea: 31, circunferencia: 71, diámetro: 22.6, us: 13.5 },
            { europea: 32, circunferencia: 72, diámetro: 22.9, us: 14 },
            { europea: 33, circunferencia: 73, diámetro: 23.2, us: 14 },
            { europea: 34, circunferencia: 74, diámetro: 23.5, us: 14.5 },
            { europea: 35, circunferencia: 75, diámetro: 23.9, us: 15 },
        ];

        const tallaCoincidente = tablaDeTallas.find(talla => talla.circunferencia === parseFloat(circunferencia));

        if (tallaCoincidente) {
            setTallaEuropea(tallaCoincidente.europea);
            setTallaUs(tallaCoincidente.us);
            setError('');
        } else {
            setError('No se encontró una talla coincidente para la circunferencia ingresada.');
        }
    }

    return (
        <AppWrapper>
            <h1 style={{ textAlign: 'center' }}>Talla de Anillos</h1>
            <Box>
                <h2>Convertidor de Talla de Anillos</h2>
                <Description>Convierte la circunferencia de tu anillo (en mm) a tallas europeas y estadounidenses.</Description>

                {tallaEuropea || tallaUs ? (
                    <>
                        <Result>
                            <p>Tu talla europea de anillo es <Highlight>{tallaEuropea}</Highlight></p>
                            <p>Tu talla estadounidense de anillo es <Highlight>{tallaUs}</Highlight></p>
                        </Result>
                        <Button onClick={reiniciarApp}>Reiniciar</Button>
                    </>
                ) : (
                    <>
                        <FormWrapper>
                            <FormField
                                id="circunferencia"
                                label="Circunferencia (en mm)"
                                type="number"
                                value={circunferencia}
                                onChange={(event) => setCircunferencia(event.target.value)}
                                placeholder='Ingresa la circunferencia (ej. 58)'
                                submitMethod={convertirTallaDeAnillo}
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </FormWrapper>
                        <Button onClick={convertirTallaDeAnillo}>Convertir</Button>
                    </>
                )}
            </Box>
            <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
                <h3 style={{ marginBottom: '10px' }}>Cómo Medir Tu Talla de Anillo</h3>
                <p style={{ marginBottom: '20px' }}>
                    Para medir tu talla de anillo con precisión, sigue estos sencillos pasos:
                    <ol>
                        <li>Envuelve un trozo de cuerda o una tira de papel alrededor de la base de tu dedo.</li>
                        <li>Marca el punto donde los extremos se encuentran con un bolígrafo.</li>
                        <li>Mide la longitud de la cuerda o el papel con una regla (en milímetros).</li>
                        <li>Ingresa la medida en el convertidor arriba para encontrar tu talla de anillo.</li>
                    </ol>
                    Asegurarte de tener la talla correcta de anillo es esencial para la comodidad y seguridad. Una medida precisa evitará que el anillo quede demasiado apretado o demasiado suelto.
                </p>
                <h3 style={{ marginBottom: '10px' }}>¿Por Qué Usar un Convertidor de Talla de Anillo?</h3>
                <p style={{ marginBottom: '20px' }}>
                    La talla de los anillos puede variar significativamente entre diferentes países. Nuestro convertidor de talla de anillos te ayuda a convertir fácilmente tu talla de anillo entre los estándares europeos y estadounidenses. Ya sea que estés comprando un anillo en línea o recibiendo un regalo, conocer la talla de anillo correcta asegurará un ajuste perfecto.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Usar nuestro convertidor de talla de anillo es simple y directo. Solo mide la circunferencia de tu dedo, ingrésala en el convertidor y obtén instantáneamente las tallas europeas y estadounidenses correspondientes.
                </p>
                <h3 style={{ marginBottom: '10px' }}>Entendiendo las Tallas de Anillo</h3>
                <p style={{ marginBottom: '20px' }}>
                    Las tallas de anillo se determinan en función de la circunferencia o el diámetro de tu dedo. En el sistema europeo, las tallas generalmente se miden en milímetros de circunferencia. En contraste, el sistema estadounidense utiliza una escala numérica donde cada incremento de media talla representa un ligero aumento en la circunferencia.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Medir la talla de anillo con precisión es crucial tanto para la comodidad como para asegurar que el anillo se mantenga en su lugar de manera segura. Es importante recordar que los dedos pueden hincharse durante el día o debido a cambios de temperatura, así que considera medir en diferentes momentos para obtener un ajuste óptimo.
                </p>
                
                {/* Ring Size Table */}
                <h3 style={{ marginBottom: '10px' }}>Tabla de Tallas de Anillos</h3>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Talla Europea</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Circunferencia (mm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Diámetro (mm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Talla US</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[
                            { europea: 6, circunferencia: 46, diámetro: 14.6, us: 4 },
                            { europea: 7, circunferencia: 47, diámetro: 15.0, us: 4 },
                            { europea: 8, circunferencia: 48, diámetro: 15.3, us: 4.5 },
                            { europea: 9, circunferencia: 49, diámetro: 15.6, us: 5 },
                            { europea: 10, circunferencia: 50, diámetro: 15.9, us: 5.5 },
                            { europea: 11, circunferencia: 51, diámetro: 16.2, us: 6 },
                            { europea: 12, circunferencia: 52, diámetro: 16.5, us: 6 },
                            { europea: 13, circunferencia: 53, diámetro: 16.8, us: 6.5 },
                            { europea: 14, circunferencia: 54, diámetro: 17.2, us: 7 },
                            { europea: 15, circunferencia: 55, diámetro: 17.5, us: 7.5 },
                            { europea: 16, circunferencia: 56, diámetro: 17.8, us: 8 },
                            { europea: 17, circunferencia: 57, diámetro: 18.1, us: 8 },
                            { europea: 18, circunferencia: 58, diámetro: 18.4, us: 8.5 },
                            { europea: 19, circunferencia: 59, diámetro: 18.8, us: 9 },
                            { europea: 20, circunferencia: 60, diámetro: 19.1, us: 9 },
                            { europea: 21, circunferencia: 61, diámetro: 19.4, us: 9.5 },
                            { europea: 22, circunferencia: 62, diámetro: 19.7, us: 10 },
                            { europea: 23, circunferencia: 63, diámetro: 20.0, us: 10.5 },
                            { europea: 24, circunferencia: 64, diámetro: 20.3, us: 10.5 },
                            { europea: 25, circunferencia: 65, diámetro: 20.6, us: 11 },
                            { europea: 26, circunferencia: 66, diámetro: 21.0, us: 11 },
                            { europea: 27, circunferencia: 67, diámetro: 21.3, us: 11.5 },
                            { europea: 28, circunferencia: 68, diámetro: 21.6, us: 12 },
                            { europea: 29, circunferencia: 69, diámetro: 22.0, us: 13 },
                            { europea: 30, circunferencia: 70, diámetro: 22.3, us: 13 },
                            { europea: 31, circunferencia: 71, diámetro: 22.6, us: 13.5 },
                            { europea: 32, circunferencia: 72, diámetro: 22.9, us: 14 },
                            { europea: 33, circunferencia: 73, diámetro: 23.2, us: 14 },
                            { europea: 34, circunferencia: 74, diámetro: 23.5, us: 14.5 },
                            { europea: 35, circunferencia: 75, diámetro: 23.9, us: 15 },
                        ].map((talla, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{talla.europea}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{talla.circunferencia}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{talla.diámetro}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{talla.us}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AppWrapper>
    );
}

export default RingSizeConverter;


