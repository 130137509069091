import React from 'react';

import Box from 'components/Box/Box';
import FormField from 'components/FormField/FormField';
import Button from 'components/Button/Button';
import Result from 'components/Result/Result';
import Highlight from 'components/Highlight/Highlight';
import Description from 'components/Description/Description';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import AppWrapper from 'components/AppWrapper/AppWrapper';

const ConvertidorTallaBrasier = () => {
    const [debajoBusto, setDebajoBusto] = React.useState('');
    const [busto, setBusto] = React.useState('');
    const [tallaCopa, setTallaCopa] = React.useState('');
    const [tallaUs, setTallaUs] = React.useState('');
    const [tallaEu, setTallaEu] = React.useState('');
    const [error, setError] = React.useState('');

    const reiniciarApp = () => {
        setDebajoBusto('');
        setBusto('');
        setTallaCopa('');
        setTallaUs('');
        setTallaEu('');
        setError('');
    }

    const convertirTallaBrasier = () => {
        if (!debajoBusto || !busto) {
            setError('¡Por favor ingrese las medidas de debajo del busto y del busto!');
            return false;
        }

        const tablaDeTallas = [
            { rangoDebajoBusto: [63, 68], rangoBusto: [77, 80], copa: 'A', us: 30, eu: 65 },
            { rangoDebajoBusto: [63, 68], rangoBusto: [82, 85], copa: 'B', us: 30, eu: 65 },
            { rangoDebajoBusto: [63, 68], rangoBusto: [88, 91], copa: 'C', us: 30, eu: 65 },
            { rangoDebajoBusto: [68, 72], rangoBusto: [85, 88], copa: 'A', us: 32, eu: 70 },
            { rangoDebajoBusto: [68, 72], rangoBusto: [88, 91], copa: 'B', us: 32, eu: 70 },
            { rangoDebajoBusto: [68, 72], rangoBusto: [93, 96], copa: 'C', us: 32, eu: 70 },
            { rangoDebajoBusto: [73, 77], rangoBusto: [90, 93], copa: 'A', us: 34, eu: 75 },
            { rangoDebajoBusto: [73, 77], rangoBusto: [93, 96], copa: 'B', us: 34, eu: 75 },
            { rangoDebajoBusto: [73, 77], rangoBusto: [98, 101], copa: 'C', us: 34, eu: 75 },
            { rangoDebajoBusto: [78, 82], rangoBusto: [95, 98], copa: 'A', us: 36, eu: 80 },
            { rangoDebajoBusto: [78, 82], rangoBusto: [98, 101], copa: 'B', us: 36, eu: 80 },
            { rangoDebajoBusto: [78, 82], rangoBusto: [104, 107], copa: 'C', us: 36, eu: 80 },
            { rangoDebajoBusto: [83, 87], rangoBusto: [100, 103], copa: 'A', us: 38, eu: 85 },
            { rangoDebajoBusto: [83, 87], rangoBusto: [103, 106], copa: 'B', us: 38, eu: 85 },
            { rangoDebajoBusto: [83, 87], rangoBusto: [109, 112], copa: 'C', us: 38, eu: 85 },
            { rangoDebajoBusto: [88, 92], rangoBusto: [105, 108], copa: 'A', us: 40, eu: 90 },
            { rangoDebajoBusto: [88, 92], rangoBusto: [108, 111], copa: 'B', us: 40, eu: 90 },
            { rangoDebajoBusto: [88, 92], rangoBusto: [114, 117], copa: 'C', us: 40, eu: 90 },
        ];

        const tallaCoincidente = tablaDeTallas.find(talla => 
            debajoBusto >= talla.rangoDebajoBusto[0] && debajoBusto <= talla.rangoDebajoBusto[1] &&
            busto >= talla.rangoBusto[0] && busto <= talla.rangoBusto[1]
        );

        if (tallaCoincidente) {
            setTallaCopa(tallaCoincidente.copa);
            setTallaUs(tallaCoincidente.us);
            setTallaEu(tallaCoincidente.eu);
            setError('');
        } else {
            setError('No se encontró una talla coincidente para las medidas ingresadas.');
        }
    }

    return (
        <AppWrapper>
            <Box>
                <h2>Convertidor de Talla de Brasier</h2>
                <Description>Convierte tus medidas de debajo del busto y del busto para encontrar tu talla de brasier en tallas estadounidenses y europeas.</Description>

                {tallaCopa || tallaUs || tallaEu ? (
                    <>
                        <Result>
                            <p>Tu talla de copa es <Highlight>{tallaCopa}</Highlight></p>
                            <p>Tu talla de brasier en EE.UU. es <Highlight>{tallaUs}</Highlight></p>
                            <p>Tu talla de brasier en Europa es <Highlight>{tallaEu}</Highlight></p>
                        </Result>
                        <Button onClick={reiniciarApp}>Reiniciar</Button>
                    </>
                ) : (
                    <>
                        <FormWrapper>
                            <FormField
                                id="debajoBusto"
                                label="Debajo del Busto (en cm)"
                                type="number"
                                value={debajoBusto}
                                onChange={(event) => setDebajoBusto(event.target.value)}
                                placeholder='Ingresa la medida de debajo del busto (ej. 70)'
                                submitMethod={convertirTallaBrasier}
                            />
                            <FormField
                                id="busto"
                                label="Busto (en cm)"
                                type="number"
                                value={busto}
                                onChange={(event) => setBusto(event.target.value)}
                                placeholder='Ingresa la medida del busto (ej. 90)'
                                submitMethod={convertirTallaBrasier}
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </FormWrapper>
                        <Button onClick={convertirTallaBrasier}>Convertir</Button>
                    </>
                )}
            </Box>
            <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
                <h3 style={{ marginBottom: '10px' }}>Cómo Medir Tu Talla de Brasier</h3>
                <p style={{ marginBottom: '20px' }}>
                    Para medir tu talla de brasier con precisión, sigue estos sencillos pasos:
                    <ol>
                        <li>Usa una cinta métrica para medir la circunferencia de tu torso justo debajo del busto. Asegúrate de que la cinta esté bien ajustada pero no demasiado apretada.</li>
                        <li>Anota la medida en centímetros. Esta es tu medida de "debajo del busto".</li>
                        <li>Luego, mide alrededor de la parte más completa de tu busto. La cinta métrica debe estar nivelada y cómoda.</li>
                        <li>Anota esta medida en centímetros. Esta es tu medida de "busto".</li>
                        <li>Ingresa ambas medidas en el convertidor de arriba para encontrar tu talla de brasier.</li>
                    </ol>
                    Obtener la talla correcta de brasier es esencial para tu comodidad y soporte. Una medida precisa asegurará que tu brasier se ajuste perfectamente.
                </p>
                <h3 style={{ marginBottom: '10px' }}>¿Por Qué Usar un Convertidor de Talla de Brasier?</h3>
                <p style={{ marginBottom: '20px' }}>
                    Las tallas de brasier pueden variar significativamente entre diferentes países y marcas. Nuestro convertidor de talla de brasier te ayuda a convertir fácilmente tus medidas entre los estándares europeos y estadounidenses. Ya sea que estés comprando un brasier en línea o en una tienda, conocer tu talla correcta asegurará un ajuste perfecto y cómodo.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Usar nuestro convertidor de talla de brasier es simple y rápido. Solo ingresa tus medidas y obtén tu talla en diferentes sistemas de talla. Asegúrate de que tu brasier te ofrezca el soporte y la comodidad que necesitas.
                </p>
                <h3 style={{ marginTop: '20px' }}>Entendiendo las Tallas de Sostén</h3>
                <p>
                    Las tallas de sostén se determinan en función de la medida del torso debajo del busto y la medida del busto. En el sistema europeo, las tallas generalmente se miden en centímetros, mientras que el sistema estadounidense utiliza una escala numérica y alfabética para representar la banda y la copa. Medir la talla de sostén con precisión es crucial tanto para la comodidad como para asegurar que el sostén proporcione el soporte adecuado. Usa nuestro convertidor para encontrar la talla perfecta y asegurarte de que tu sostén se ajuste de manera óptima.
                </p>
                <h3 style={{ marginTop: '20px' }}>Tabla de Tallas de Brasier</h3>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Rango Debajo del Busto (cm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Rango Busto (cm)</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Copa</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Talla EE.UU.</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Talla Europa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[
                            { rangoDebajoBusto: '63-68', rangoBusto: '77-80', copa: 'A', us: '30', eu: '65' },
                            { rangoDebajoBusto: '63-68', rangoBusto: '82-85', copa: 'B', us: '30', eu: '65' },
                            { rangoDebajoBusto: '63-68', rangoBusto: '88-91', copa: 'C', us: '30', eu: '65' },
                            { rangoDebajoBusto: '68-72', rangoBusto: '85-88', copa: 'A', us: '32', eu: '70' },
                            { rangoDebajoBusto: '68-72', rangoBusto: '88-91', copa: 'B', us: '32', eu: '70' },
                            { rangoDebajoBusto: '68-72', rangoBusto: '93-96', copa: 'C', us: '32', eu: '70' },
                            { rangoDebajoBusto: '73-77', rangoBusto: '90-93', copa: 'A', us: '34', eu: '75' },
                            { rangoDebajoBusto: '73-77', rangoBusto: '93-96', copa: 'B', us: '34', eu: '75' },
                            { rangoDebajoBusto: '73-77', rangoBusto: '98-101', copa: 'C', us: '34', eu: '75' },
                            { rangoDebajoBusto: '78-82', rangoBusto: '95-98', copa: 'A', us: '36', eu: '80' },
                            { rangoDebajoBusto: '78-82', rangoBusto: '98-101', copa: 'B', us: '36', eu: '80' },
                            { rangoDebajoBusto: '78-82', rangoBusto: '104-107', copa: 'C', us: '36', eu: '80' },
                            { rangoDebajoBusto: '83-87', rangoBusto: '100-103', copa: 'A', us: '38', eu: '85' },
                            { rangoDebajoBusto: '83-87', rangoBusto: '103-106', copa: 'B', us: '38', eu: '85' },
                            { rangoDebajoBusto: '83-87', rangoBusto: '109-112', copa: 'C', us: '38', eu: '85' },
                            { rangoDebajoBusto: '88-92', rangoBusto: '105-108', copa: 'A', us: '40', eu: '90' },
                            { rangoDebajoBusto: '88-92', rangoBusto: '108-111', copa: 'B', us: '40', eu: '90' },
                            { rangoDebajoBusto: '88-92', rangoBusto: '114-117', copa: 'C', us: '40', eu: '90' },
                        ].map((fila, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{fila.rangoDebajoBusto}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{fila.rangoBusto}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{fila.copa}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{fila.us}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{fila.eu}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AppWrapper>
    );
}

export default ConvertidorTallaBrasier;
