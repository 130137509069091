export const theme = {
    colors: {
        background: '#F0F0F0',  // Light gray background
        text: '#333333',        // Dark gray text
        primary: '#FFFFFF',     // White for primary elements
        secondary: '#E0E0E0',   // Light gray for secondary elements
        gradient: 'linear-gradient(90deg, #FFD700 0%, #FF8C00 104.62%)', // Gradient from gold to dark orange
    },
    font: {
        family: 'Montserrat',
        size: '16px',
        weight: {
            regular: 400,
            medium: 500,
            semibold: 600,
            bold: 700,
            extraBold: 800,
        },
    },
}
