// Icons
import bmiIcon from "assets/icons/bmi.svg"
import waterIcon from "assets/icons/water.svg"
import caloriesIcon from "assets/icons/calories.svg"
import whrIcon from "assets/icons/whr.svg"

import AnilloIcon from "assets/icons/Anillo.svg"
import BrasierIcon from "assets/icons/Brasier.svg"
import PantalonesIcon from "assets/icons/Pantalones.svg"
import ZapatosIcon from "assets/icons/Zapatos.svg"
import TenisIcon from "assets/icons/Tennis.svg"

import Anillos from "views/Anillo"
import Brasier from "views/Brasier"
import Pantalones from "views/Pantalones"
import Zapatos from "views/Zapatos"
import Tenis from "views/Tenis"
import Acerca from "views/Acerca"


export const routes = [
    {
        name: 'Inicio',
        path: '/',
    },
    {
        name: 'Talla de Anillos',
        path: '/Anillos',
        component: <Anillos />,
        icon: AnilloIcon,
    },
{
        name: 'Talla de Brasier',
        path: '/Brasier',
        component: <Brasier />,
        icon: BrasierIcon,
    },

    {
        name: 'Talla de Tenis',
        path: '/Tenis',
        component: <Tenis />,
        icon: TenisIcon,
    },
            
{
        name: 'Talla de Pantalones',
        path: '/Pantalones',
        component: <Pantalones />,
        icon: PantalonesIcon,
    },
{
        name: 'Talla de Zapatos',
        path: '/Zapatos',
        component: <Zapatos />,
        icon: ZapatosIcon,
    },
    
    
   {
        name: 'Acerca',
        path: '/Acerca',
        component: <Acerca />
    },
             
            
    
]
