import React from "react";
import styled from "styled-components";

import Box from "components/Box/Box";
import AppWrapper from "components/AppWrapper/AppWrapper";

const Heading = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
  }
`;

const SubHeading = styled.h2`
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const List = styled.ol`
  margin-left: 20px;
  margin-bottom: 20px;

  li {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const AboutUs = () => {
  return (
    <AppWrapper>
      <Box>
        <Heading>
          <h1>Sobre Nosotros</h1>
          <p>
            ¡Bienvenido a nuestra plataforma de conversión de tallas entre México y Estados Unidos! Aquí podrás encontrar herramientas como nuestra <strong>calculadora de tallas</strong> de zapatos, anillos, pantalones, camisetas, sostenes y condones, que te ayudarán a encontrar la talla perfecta sin complicaciones.
          </p>
        </Heading>

        <SubHeading>¿Quiénes Somos?</SubHeading>
        <Paragraph>
          Somos un equipo apasionado por ofrecer soluciones prácticas para quienes necesitan conocer su <strong>talla de zapatos en USA y México</strong>, su talla de anillo o cualquier otra talla de ropa. Nuestra misión es simplificar el proceso de compra internacional al ofrecer herramientas precisas de conversión de tallas.
        </Paragraph>

        <SubHeading>¿Qué Ofrecemos?</SubHeading>
        <Paragraph>
          Ofrecemos una serie de herramientas y recursos que incluyen:
        </Paragraph>
        <List>
          <li><strong>Calculadora de Talla de Zapatos</strong>: Convierte fácilmente entre tallas de zapatos en USA y México.</li>
          <li><strong>Calculadora de Talla de Anillos</strong>: Averigua <strong>cómo saber la talla de anillo</strong> exacta.</li>
          <li><strong>Calculadora de Tallas de Pantalones</strong>: Encuentra la talla de pantalón en USA y México.</li>
          <li><strong>Calculadora de Tallas de Sostenes</strong>: Descubre <strong>cómo saber qué talla de brasier soy</strong> en distintas regiones.</li>
          <li><strong>Calculadora de Tallas de Condones</strong>: Asegúrate de encontrar la talla correcta para mayor comodidad.</li>
        </List>

        <SubHeading>¿Por Qué Elegirnos?</SubHeading>
        <List>
          <li><strong>Herramientas Precisas</strong>: Utiliza nuestras herramientas de conversión para encontrar la talla perfecta.</li>
          <li><strong>Actualización Constante</strong>: Nos aseguramos de que nuestras herramientas estén siempre actualizadas con las últimas guías de tallas.</li>
          <li><strong>Fácil de Usar</strong>: Nuestra plataforma es intuitiva y está diseñada para facilitarte la vida.</li>
        </List>

        <Paragraph>
          Únete a nosotros y simplifica tu experiencia de compra internacional. Explora nuestro sitio y descubre cómo podemos ayudarte a encontrar la talla perfecta hoy mismo.
        </Paragraph>
      </Box>
    </AppWrapper>
  );
};

export default AboutUs;
